import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import getEffectiveness from "./getEffectiveness";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const typeList = [
  { type: "dark", label: "あく", color: "#6781D4" },
  { type: "rock", label: "いわ", color: "#FAC727" },
  { type: "psychic", label: "エスパー", color: "#EC87F4" },
  { type: "fighting", label: "かくとう", color: "#EE6969" },
  { type: "grass", label: "くさ", color: "#9AC30E" },
  { type: "ice", label: "こおり", color: "#60E9F5" },
  { type: "ghost", label: "ゴースト", color: "#756EB4" },
  { type: "ground", label: "じめん", color: "#C8A841" },
  { type: "electric", label: "でんき", color: "#E6D400" },
  { type: "poison", label: "どく", color: "#AB7ACA" },
  { type: "dragon", label: "ドラゴン", color: "#FF8859" },
  { type: "normal", label: "ノーマル", color: "#AEAEAE" },
  { type: "steel", label: "はがね", color: "#818AA4" },
  { type: "flying", label: "ひこう", color: "#64A7F1" },
  { type: "fairy", label: "フェアリー", color: "#FC7799" },
  { type: "fire", label: "ほのお", color: "#FFA766" },
  { type: "water", label: "みず", color: "#64C5F7" },
  { type: "bug", label: "むし", color: "#51CB5A" }
];

function typeToLabel(type) {
  return typeList.find(t => t.type === type).label;
}

function TypeSelect({ type1, type2, onType1Change, onType2Change }) {
  const classes = useStyles();
  const handleType1Change = event => {
    onType1Change(event.target.value);
    onType2Change("");
  };
  const handleType2Change = event => {
    onType2Change(event.target.value);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Container maxWidth="sm">
        相手ポケモンのタイプを選択してください。
      </Container>
      <Container maxWidth="sm">
        <FormControl className={classes.formControl}>
          <InputLabel id="type1-label">タイプ1</InputLabel>
          <Select
            labelId="type1-label"
            id="type1-select"
            value={type1}
            onChange={handleType1Change}
          >
            {typeList.map(({ type, label }) => (
              <MenuItem value={type}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="type2-label">タイプ2</InputLabel>
          <Select
            labelId="type2-label"
            id="type2-select"
            value={type2}
            onChange={handleType2Change}
          >
            <MenuItem value={""}>未選択</MenuItem>
            {typeList
              .filter(({ type }) => type != type1)
              .map(({ type, label }) => (
                <MenuItem value={type}>{label}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Container>
    </div>
  );
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#EEEEEE"
  },
  body: {
    fontSize: 16
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

function ResultTable({ type1, type2 }) {
  const rows = typeList
    .map(({ type, label, color }) => {
      const resistantTypes = [];
      if (getEffectiveness(type, type1) < 1) {
        resistantTypes.push(type1);
      }
      if (type2 && getEffectiveness(type, type2) < 1) {
        resistantTypes.push(type2);
      }
      return {
        type,
        label,
        color,
        effectiveness: type2
          ? getEffectiveness(type1, type) * getEffectiveness(type2, type)
          : getEffectiveness(type1, type),
        resistantTypes
      };
    })
    .filter(({ effectiveness }) => effectiveness != 1)
    .sort((a, b) => b.effectiveness - a.effectiveness);

  const tableRows = rows.map(row => {
    const cellStyle = {
      backgroundColor: row.effectiveness > 1 ? row.color : "#AAAAAA",
      fontWeight:
        row.effectiveness === 4 || row.effectiveness === 0 ? "bold" : "normal"
    };
    return (
      <StyledTableRow key={row.type}>
        <StyledTableCell component="th" scope="row" style={cellStyle}>
          {row.label}
          <br />
          <div style={{ fontSize: "12px" }}>
            {row.resistantTypes.length > 0
              ? "（" +
                row.resistantTypes.map(type => typeToLabel(type)).join("・") +
                "耐性）"
              : ""}
          </div>
        </StyledTableCell>
        <StyledTableCell align="right" style={cellStyle}>
          x {row.effectiveness}
        </StyledTableCell>
      </StyledTableRow>
    );
  });

  return (
    <TableContainer component={Paper} style={{ marginTop: "20px" }}>
      <Table size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>攻撃のタイプ</StyledTableCell>
            <StyledTableCell align="right">倍率</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default function App() {
  const [type1, setType1] = useState("dark");
  const [type2, setType2] = useState("");
  const onType1Change = type => {
    setType1(type);
  };
  const onType2Change = type => {
    setType2(type);
  };

  return (
    <Container maxWidth="sm">
      <TypeSelect
        type1={type1}
        type2={type2}
        onType1Change={onType1Change}
        onType2Change={onType2Change}
      />
      <ResultTable type1={type1} type2={type2} />
    </Container>
  );
}
