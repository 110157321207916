const effectivenessChart = {
  normal: {
    fighting: 2,
    ghost: 0
  },
  fire: {
    fire: 0.5,
    water: 2,
    grass: 0.5,
    ice: 0.5,
    ground: 2,
    bug: 0.5,
    rock: 2,
    steel: 0.5,
    fairy: 0.5
  },
  water: {
    fire: 0.5,
    water: 0.5,
    electric: 2,
    grass: 2,
    ice: 0.5,
    steel: 0.5
  },
  electric: {
    electric: 0.5,
    ground: 2,
    flying: 0.5,
    steel: 0.5
  },
  grass: {
    fire: 2,
    water: 0.5,
    electric: 0.5,
    grass: 0.5,
    ice: 2,
    poison: 2,
    ground: 0.5,
    flying: 2,
    bug: 2
  },
  ice: {
    fire: 2,
    ice: 0.5,
    fighting: 2,
    rock: 2,
    steel: 2
  },
  fighting: {
    flying: 2,
    psychic: 2,
    bug: 0.5,
    rock: 0.5,
    dark: 0.5,
    fairy: 2
  },
  poison: {
    grass: 0.5,
    fighting: 0.5,
    poison: 0.5,
    ground: 2,
    psychic: 2,
    bug: 0.5,
    fairy: 0.5
  },
  ground: {
    water: 2,
    electric: 0,
    grass: 2,
    ice: 2,
    poison: 0.5,
    rock: 0.5
  },
  flying: {
    electric: 2,
    grass: 0.5,
    ice: 2,
    fighting: 0.5,
    ground: 0,
    bug: 0.5,
    rock: 2
  },
  psychic: {
    fighting: 0.5,
    psychic: 0.5,
    bug: 2,
    ghost: 2,
    dark: 2
  },
  bug: {
    fire: 2,
    grass: 0.5,
    fighting: 0.5,
    ground: 0.5,
    flying: 2,
    rock: 2
  },
  rock: {
    normal: 0.5,
    fire: 0.5,
    water: 2,
    grass: 2,
    fighting: 2,
    poison: 0.5,
    ground: 2,
    flying: 0.5,
    steel: 2
  },
  ghost: {
    normal: 0,
    fighting: 0,
    poison: 0.5,
    bug: 0.5,
    ghost: 2,
    dark: 2
  },
  dragon: {
    fire: 0.5,
    water: 0.5,
    electric: 0.5,
    grass: 0.5,
    ice: 2,
    dragon: 2,
    fairy: 2
  },
  dark: {
    fighting: 2,
    psychic: 0,
    bug: 2,
    ghost: 0.5,
    dark: 0.5,
    fairy: 2
  },
  steel: {
    normal: 0.5,
    fire: 2,
    grass: 0.5,
    ice: 0.5,
    fighting: 2,
    poison: 0,
    ground: 2,
    flying: 0.5,
    psychic: 0.5,
    bug: 0.5,
    rock: 0.5,
    dragon: 0.5,
    steel: 0.5,
    fairy: 0.5
  },
  fairy: {
    fighting: 0.5,
    poison: 2,
    bug: 0.5,
    dragon: 0,
    dark: 0.5,
    steel: 2
  }
};

export default function(enemyType, attackType) {
  if (attackType in effectivenessChart[enemyType]) {
    return effectivenessChart[enemyType][attackType];
  }
  return 1;
}
